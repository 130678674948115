import iconOne from '../../assets/service/1.jpg';
import iconTwo from '../../assets/service/2.jpg';
import iconThree from '../../assets/service/3.jpg';
import iconFour from '../../assets/service/4.jpg';
import iconFive from '../../assets/service/5.jpg';
import iconSix from '../../assets/service/6.jpg';
import iconSeven from '../../assets/service/7.jpg';
import iconEight from '../../assets/service/8.jpg';


const ServicesData = [
    {
        'icon': iconOne,
        'title': 'Implantologie',
        'description': 'Restaurez votre sourire avec des implants dentaires sûrs et durables.',
    },
    {
        'icon': iconSeven,
        'title': 'Endodontie',
        'description': 'Sauvez vos dents endommagées avec des traitements endodontiques de pointe.',
    },
    {
        'icon': iconThree,
        'title': 'Esthétique dentaire',
        'description': 'Transformez votre sourire avec des solutions esthétiques innovantes.',
    },
    {
        'icon': iconFive,
        'title': 'Pédodontie',
        'description': 'Proposez à vos enfants des soins dentaires doux et adaptés à leur jeune âge.',
    },
    {
        'icon': iconFour,
        'title': 'Smile design',
        'description': 'Créez un sourire personnalisé qui reflète votre style et votre personnalité.',
    },
    {
        'icon': iconSix,
        'title': 'Couronne céramique',
        'description': 'Retrouvez esthétique et fonctionnalité avec des couronnes en céramique et zirconium de haute qualité.',
    },
    {
        'icon': iconTwo,
        'title': 'Orthodontie , Invisalign',
        'description': 'Alignez vos dents de manière discrète et confortable avec Invisalign.',
    },
    {
        'icon': iconEight,
        'title': 'Chirurgie buccale',
        'description': 'Résolvez les problèmes dentaires complexes avec notre expertise en chirurgie buccale.',
    }
];


export default ServicesData;